import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	quickResponsesData: [],
	selectedEnterprise: null,
    isLoadingquickResponses: false,
	quickResponsesCount: 0,
	quickResponsesPageNo: 0,
};

export const quickResponseSlice = createSlice({
	name: 'quickResponses',
	initialState,
	reducers: {
		updateQuickResponsesLoadingStatus: (state, action) => {
			state.isLoadingquickResponses = action.payload;
		},
		updateSelectedEnterprise: (state, action) => {
            state.selectedEnterprise = action.payload;
        },
		updateQuickResponsesData: (state, action) => {
			state.quickResponsesData = action.payload;
		},
		updateQuickResponsesCount: (state, action) => {
			state.quickResponsesCount = action.payload;
		},
		updateQuickResponsesPageNo: (state, action) => {
			state.quickResponsesPageNo = action.payload;
		},
	},
});

export const {
	updateQuickResponsesLoadingStatus,
	updateSelectedEnterprise,
	updateQuickResponsesData,
	updateQuickResponsesCount,
	updateQuickResponsesPageNo,
} = quickResponseSlice.actions;
export default quickResponseSlice.reducer;