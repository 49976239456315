import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	tagsData: [],
	selectedEnterprise: null,
    isLoadingTags: false,
	tagsCount: 0,
	tagsPageNo: 0,
};

export const tagsSlice = createSlice({
	name: 'tags',
	initialState,
	reducers: {
		updateTagsLoadingStatus: (state, action) => {
			state.isLoadingTags = action.payload;
		},
		updateSelectedEnterprise: (state, action) => {
            state.selectedEnterprise = action.payload;
        },
		updateTagsData: (state, action) => {
			state.tagsData = action.payload;
		},
		updateTagsCount: (state, action) => {
			state.tagsCount = action.payload;
		},
		updateTagsPageNo: (state, action) => {
			state.tagsPageNo = action.payload;
		},
	},
});

export const {
	updateTagsLoadingStatus,
	updateSelectedEnterprise,
	updateTagsData,
	updateTagsCount,
	updateTagsPageNo,
} = tagsSlice.actions;
export default tagsSlice.reducer;