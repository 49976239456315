import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConfirmContextProvider } from "./store/confirmation/ConfirmContextProvider";
import ConfirmDialog from "./components/confirmDialog/ConfirmDialog";
import { Provider } from 'react-redux'
import { store } from "./core/redux/store";

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ConfirmContextProvider>
				<App />
				<ConfirmDialog />
			</ConfirmContextProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
);
