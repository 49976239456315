import { authHeader } from './auth-header';
import { handleResponse } from './handle-response';

export const fetchWrapper = {
	get,
	post,
	put,
	patch,
	delete: _delete,
};

/**
 * @author https://github.com/cthacker-udel
 * @param {object} queryParams The query parameters to append to the url
 * @returns {string} The generated url with query parameters
 * @example
 * // example 1
 * return generateUrl({ 'hello': 'world' });
 * // returns ?hello=world
 *
 * // example 2
 * return generateUrl({ 'hello': 'world', 'this': 'works' });
 * // returns ?hello=world&this=works
 */
export const generateQueryString = (queryParams) => {
	return `${
		queryParams
			? '?' +
			  Object.keys(queryParams)
					.filter((e) => queryParams[e] !== undefined)
					.map((e) => `${e}=${queryParams[e]}`)
					.join('&')
			: ''
	}`;
};

function get(url) {
	const requestOptions = {
		method: 'GET',
		headers: { 'Content-Type': 'application/json', ...authHeader() },
	};
	return fetch(url, requestOptions).then(handleResponse);
}

const getMultipartValue = (val) => {
    if (val instanceof File) {
        return val;
    } else if (typeof val === 'object' && val !== null) {
        return JSON.stringify(val);
    } else {
        return val;
    }
};

function post(url, body, contentType = 'application/json', signal) {
	var requestOptions = {
		method: 'POST',
		headers: authHeader(),
		signal,
	};

	switch (contentType) {
		default:
		case 'application/json': {
			requestOptions.headers['Content-Type'] = contentType;
			requestOptions.body = JSON.stringify(body);
			break;
		}
		case 'multipart/form-data': {
			//requestOptions.headers['Content-Type'] = contentType;
			if (body) {
				const formData = new FormData();
				for (let [key, value] of Object.entries(body)) {
					if (value) {
						// Clean up files[0], files[1]
						// if (key.includes('files')) formData.append('files', value);
						// Array.isArray(v1);
						// else {

						if (Array.isArray(value)) {
							value.forEach((dt) => {
								formData.append(key, getMultipartValue(dt));
							});
						} else {
							formData.append(key, getMultipartValue(value));
						}
					}
				}
				requestOptions.body = formData;
			}
			break;
		}
		case 'application/x-www-form-urlencoded': {
			requestOptions.headers['Content-Type'] = contentType;
			if (body) {
				var formBody = [];
				for (const [key, value] of Object.entries(body)) {
					var encodedKey = encodeURIComponent(key);
					var encodedValue = encodeURIComponent(value);
					formBody.push(encodedKey + '=' + encodedValue);
				}
				requestOptions.body = formBody.join('&');
			}
			break;
		}
	}
	return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
	const requestOptions = {
		method: 'PUT',
		headers: { 'Content-Type': 'application/json', ...authHeader() },
		body: JSON.stringify(body),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
function patch(url, body) {
	const requestOptions = {
		method: 'PATCH',
		headers: { 'Content-Type': 'application/json', ...authHeader() },
		body: JSON.stringify(body),
	};
	return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, body) {
	const requestOptions = {
		method: 'DELETE',
		headers: { 'Content-Type': 'application/json', ...authHeader() },
		body: JSON.stringify(body),
	};
	return fetch(url, requestOptions).then(handleResponse);
}
