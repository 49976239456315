import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    popupState: false,
    popupType: '',
    showPopupCloseButton: true,
    closePopupOnClickOutside: true,
    popupMaxWidth: 'sm',  //	'xs'| 'sm'| 'md'| 'lg'| 'xl'| false| string
    isFullScreenPopup: false,
    popupScrollType: 'paper', // 'body'| 'paper'
    popupDataObj: {},
}

export const popupSlice = createSlice({
    name: 'popup',
    initialState,
    reducers: {
        closePopupAction: () => initialState,

        updatePopupSliceAction: (state, action) => {
            state.popupState = action.payload.popupState;
            state.popupType = action.payload.popupType;
            state.showPopupCloseButton = action.payload.showPopupCloseButton;
            state.closePopupOnClickOutside = action.payload.closePopupOnClickOutside;
            state.popupMaxWidth = action.payload.popupMaxWidth;
            state.popupScrollType = action.payload.popupScrollType;
            state.popupDataObj = action.payload.popupDataObj;
        },
    },
})

// Action creators are generated for each case reducer function
export const { closePopupAction, updatePopupSliceAction } = popupSlice.actions

export default popupSlice.reducer