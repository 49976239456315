import { BehaviorSubject } from 'rxjs';
import config from 'src/config';
import jwtDecode from 'jwt-decode';
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import { alertService } from './alert.service';
import { utilityService } from './utility.service';

const token$ = new BehaviorSubject(localStorage.getItem('token'));
const profile$ = new BehaviorSubject(
	JSON.parse(localStorage.getItem('profile')),
);
const settings$ = new BehaviorSubject(
	JSON.parse(localStorage.getItem('enterprise-settings')),
);
const roles$ = new BehaviorSubject(JSON.parse(localStorage.getItem('roles')));
const view$ = new BehaviorSubject(localStorage.getItem('view'));

const login = async (email, password, isEndUserLogin, eid) => {
	let response = await fetchWrapper.post(
		isEndUserLogin && eid
			? `${config.apiURL}/auth/contact-login`
			: `${config.apiURL}/auth/agent-login`,
		{ email, password, eid },
	);

	if (response.access_token) {
		await saveToken(response.access_token);
		return response;
	}
};

const saveToken = async (token) => {
	if (token) {
		localStorage.setItem('token', token);
		token$.next(token);

		const decoded = jwtDecode(token);
		localStorage.setItem('profile', JSON.stringify(decoded));
		profile$.next(decoded);

		//Populate Roles
		await getRoles();
	}
};

const getSSOProfiles = async (enterpriseId, needSAMLRequest = false) => {
	let response = await fetchWrapper.post(
		`${config.apiURL}${config.getSSODetails}`,
		{ enterpriseId, needSAMLRequest },
	);

	return response?.data ?? [];
};

const getSignInOptions = async (enterpriseId) => {
	let response = await fetchWrapper.post(
		`${config.apiURL}${config.getSignInOptions}`,
		{ enterpriseId },
	);

	return response?.data ?? '';
};

const getRoles = async () => {
	let response = await fetchWrapper.post(
		`${config.apiURL}${config.getRoles}`,
	);
	if (response.data) {
		localStorage.setItem('roles', JSON.stringify(response.data));
		roles$.next(response.data);
	}
};

function logout() {
	localStorage.removeItem('token');
	localStorage.removeItem('profile');
	localStorage.removeItem('roles');
	token$.next(null);
	profile$.next(null);
	roles$.next(null);
	if (!window.location.pathname.includes('/login'))
		window.location = '/login';
}

function setSettings(settings) {
	settings$.next(settings);
}

function setProfile(profile){
	profile$.next(profile);
}

function checkForRolePermitted(roleName, enterpriseId) {
	// Root user Tresspassing
	if (roles$.value?.some((ent) => ent?.permissions?.includes('Root.Yes'))) {
		return true;
	}

	// If Role Check Required for one Enterprise
	if (enterpriseId) {
		const enterpriseRole = roles$.value.find(
			(e) => e.enterpriseId === enterpriseId,
		);
		if (enterpriseRole) {
			return (
				enterpriseRole?.permissions?.includes('Admin.CanView') ||
				enterpriseRole?.permissions?.includes(roleName)
			);
		}
		return false;
	}

	return roles$.value?.some((ent) => ent?.permissions?.includes(roleName));
}

const verifySSO = async (payload) => {
	return await fetchWrapper.post(
		`${config.apiURL}${config.ssoVerifyUrl}`,
		payload,
	);
};

const impersonateLogin = async (email) => {
	try {
		utilityService.showLoader();
		let response = await fetchWrapper.post(
			`${config.apiURL}${config.impersonate}`,
			{ email },
		);
		if (response?.access_token) {
			localStorage.setItem('obo-token', token$.value);
			localStorage.setItem('obo-name', profile$.value.name);
			await saveToken(response.access_token);
			return true;
		}
	} catch (e) {
		alertService.error(e);
	} finally {
		utilityService.removeLoader();
	}
};
const impersonateLogout = async () => {
	utilityService.showLoader();
	await saveToken(localStorage.getItem('obo-token'));
	localStorage.removeItem('obo-token');
	localStorage.removeItem('obo-name');
	utilityService.removeLoader();
};

const getCountOfEnterpriseForTheRole = (role="Admin.CanView") => {
	let enterprise = roles$.value?.filter((ent) =>
		ent.permissions?.includes(role),
	);
	return { count: enterprise.length, enterprises: enterprise };
};

export const profileService = {
	login,
	logout,
	setSettings,
	setProfile,
	setViewId,
	checkForRolePermitted,
	impersonateLogin,
	impersonateLogout,
	getCountOfEnterpriseForTheRole,
	get token() {
		return token$.value;
	},
	get profile() {
		return profile$.value;
	},
	get isAdmin() {
		return (
			roles$.value?.some((ent) =>
				ent.permissions?.includes('Root.Yes'),
			) ||
			roles$.value?.some((ent) =>
				ent.permissions?.includes('Admin.CanView'),
			)
		);
	},
	get isSuperAdmin() {
		return roles$.value?.some((ent) =>
			ent.permissions?.includes('Root.Yes'),
		);
	},
	get isAgent() {
		return (
			roles$.value?.some((ent) =>
				ent.permissions?.includes('Root.Yes'),
			) ||
			roles$.value?.some((ent) => ent.permissions?.includes('Agents.Yes'))
		);
	},
	get isEndUser() {
		return roles$.value?.some((ent) =>
			ent.permissions?.includes('EndUser.Yes'),
		);
	},
	get impersonatedProfile() {
		return localStorage.getItem('obo-name');
	},
	get settings() {
		return settings$.value;
	},
	get getViewId() {
		return view$.value ?? '';
	},
	getSSOProfiles,
	getSignInOptions,
	saveToken,
	verifySSO,
};

function setViewId(viewId) {
	localStorage.setItem('view', viewId);
	view$.next(viewId);
}
