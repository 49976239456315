import React from 'react';
import useConfirm from '../../hooks/useConfirm';
import { Modal } from 'react-bootstrap';

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();

    return <Modal show={confirmState.show} onHide={onCancel}>
        <Modal.Body>
            <p>{confirmState?.text ? confirmState.text : ""}</p>
        </Modal.Body>
        <Modal.Footer>
            <button className="button button--primary button--small button--rounded mr-2" onClick={onConfirm} >
                Yes
            </button>
            <button onClick={onCancel} className="button button--accent button--small button--rounded">
            Cancel
            </button>

        </Modal.Footer>
    </Modal>
};
export default ConfirmDialog;