import { fetchWrapper } from 'src/utils/fetch-wrapper';
import config from 'src/config';
import {
	updateLoadingStatus,
	updateEmailTemplatesData,
	updateCount,
	updatePageNo,
	updateType,
	updateSelectedEnterprise,
	updateTemplateVariables,
	updateMailBoxData,
	updateTemplateCategory,
} from './emailSlice';

export const fetchEmailTemplate =
	({ enterpriseId, category = 0, page = 0, type = 0 }) =>
	(dispatch) => {
		dispatch(updateLoadingStatus(true));
		dispatch(updateType('agents-email'));
		dispatch(updateEmailTemplatesData([]));
		dispatch(updateTemplateCategory(category));
		fetchWrapper
			.post(config.apiURL + '/admin/template/all', {
				page: page,
				size: 10,
				enterpriseId: enterpriseId,
				category: category,
				type,
			})
			.then((res) => {
				dispatch(updateEmailTemplatesData(res.data?.rows ?? []));
				dispatch(updateLoadingStatus(false));
				dispatch(updateCount(res.data?.total));
				dispatch(updatePageNo(page));
			})
			.catch((e) => {
				//TODO: handle error
				dispatch(updateLoadingStatus(false));
			});
	};
export const fetchTemplateVariables =
	(type = 0) =>
	(dispatch) => {

		fetchWrapper
			.post(config.apiURL + '/admin/template/variables/all', {
				type,
			})
			.then((res) => {
				dispatch(updateTemplateVariables(res.data ?? []));
			})
			.catch((e) => {
				//TODO: handle error
			});
	};

export const updateSelectedEnterpriseandFetchEmailTemplate =
	(enterprise) => (dispatch) => {
		dispatch(fetchEmailTemplate({ enterpriseId: enterprise.id }));
		dispatch(fetchMailBoxData(enterprise.id));
		dispatch(updateSelectedEnterprise(enterprise));
	};

export const fetchMailBoxData =
	({ enterpriseId, page = 0, type = 0 }) =>
	(dispatch) => {
		fetchWrapper
			.post(config.apiURL + '/admin/mailbox/all', {
				page: page,
				size: 10,
				enterpriseId: enterpriseId,
				type,
			})
			.then((res) => {
				dispatch(updateMailBoxData(res.data ?? []));
			})
			.catch((e) => {
				//TODO: handle error
			});
	};

export const deleteMailBox = (id, enterpriseId) => (dispatch) => {
	fetchWrapper
		.delete(config.apiURL + `/admin/mailbox/${id}`)
		.then((res) => {
			dispatch(
				fetchMailBoxData({
					enterpriseId: enterpriseId,
				}),
			);
		})
		.catch((e) => {
			//TODO: handle error
			dispatch(fetchMailBoxData({ enterpriseId: enterpriseId }));
		});
};
