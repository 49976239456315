import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { profileService } from 'src/services/profile.service';
import { alertService } from 'src/services/alert.service';
import config from '../../../config';

export const SSOCallback = () => {
    const [returnParams, __] = useSearchParams();
    useEffect(() => {
        if (returnParams.get('error')) {
            alertService.error(returnParams.get('error'))
        }
        else {
            profileService
                .verifySSO({
                    code: returnParams.get('code'),
                    id: returnParams.get('id'),
                    state: returnParams.get('state'),
                    redirectUri: config.baseUrl + config.ssoRedirectUrl + returnParams.get('id') +
                        '&response_type=code&scope=openid&state=' + returnParams.get('id')
                })
                .then(response => {
                    window.location = response.url;
                });
        }
    }, []);
    return (
        <></>
    )
}


export default SSOCallback;