import { profileService } from 'src/services/profile.service';

export function handleResponse(response) {
    return response.text().then((text) => {
        const isJson = response.headers.get('content-type')?.includes('application/json');
        let data = text;
        if (data && isJson) data = JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                profileService.logout();
            } else if (response.status === 403) {
                return Promise.reject('You dont have required permission to action this, please reach out to your Enterprise Admin to grant you the permissions.');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
