import dataSet from '../../../mockDB/dataSet';
import moment from 'moment';

export const InitialState = {
    currentTicket: {
        _id: 1,
        requesterId: [2],
        receiverId: [9],
        subject: 'Temporary Ticket',
        priority: 1,
        status: 'New',
        creationDate: moment(),
        lastUpdated: moment(),
        history: [],
        updateHistory: []
    },
    tickets: dataSet.tickets,
    historicalTickets: dataSet.historicaltickets,
    selectedTickets: [],
    selectedUsers: [],
    users: dataSet.users,
    mergeModalDisplay: false,

    historyChanges: 5,
    historyPages: [],
    currentHistoryChangePage: 1,

    files: [],
    fileNames: [],
    fileInfo: [],
    baseMergeTicket: {},
    mergingIntoTickets: [],
    mergingMessage: '',
    isOpenTickets: true,
    sortings: {
        'id': 0,
        'subject': 0,
        'requesters': 0,
        'receivers': 0,
        'priority': 0,
        'status': 0,
        'created': 0,
        'modified': 0,
    },

    /*
  
    Bottom toolbar props
  
    */

    selectedTicketOption: false,
    loading: false,

    // Admin config

    currentPage: 'admin',
    currentRightPage: 'email',
    adminModals: {
        "email": false,
        "satisfaction-survey": false,
        "products": false
    },
    selectedEnterpriseForProductTab: null,
    enterpriseList: null

};
