import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  surveysData:[],
  selectedBrand: null
}

export const surveySlice = createSlice({
    name: 'surveys',
    initialState,
    reducers: {
        updateSelectedBrand:(state,action)=>{
            state.selectedBrand = action.payload
        },
    },
})  

// Action creators are generated for each case reducer function
export const {updateSelectedBrand} = surveySlice.actions

export default surveySlice.reducer