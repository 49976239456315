import moment from 'moment';

/*

UpdateHistory interface:

{

    user: string (user that created the change)
    date: string (date that the change happened)
    description: string (description of what the change is)

}

*/

const dataSet = {
	historicaltickets: [],

	idUsers: [
		{
			_id: 1,
			admin: false,
			avatar: 'avatar1.jpg',
			email: 'gbusy@gmail.com',
			company: 'Quantum',
			name: 'Gary Busy',
			password: 'sky74',
			phone: '531-405-5762',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 2,
			admin: true,
			avatar: 'avatar2.jpg',
			email: 'dlynch37@gmail.com',
			company: 'Quantum',
			name: 'David Lynch',
			password: 'nolimes',
			phone: '647-331-9150',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 3,
			admin: false,
			avatar: 'avatar3.jpg',
			email: 'mike.shannon@gmail.com',
			company: 'Quantum',
			name: 'Michael Shannon',
			password: 'hist95',
			phone: '444-272-5823',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 4,
			admin: false,
			avatar: 'avatar4.jpg',
			email: 'mhouser@gmail.com',
			company: 'Quantum',
			name: 'Michael Houser',
			password: 'list92',
			phone: '342-660-6940',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 5,
			admin: false,
			avatar: 'avatar5.jpg',
			email: 'phillesh10@gmail.com',
			company: 'Quantum',
			name: 'Phil Lesh',
			password: 'kist75',
			phone: '986-743-7454',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 6,
			admin: false,
			avatar: 'avatar6.jpg',
			email: 'wmarsalis@gmail.com',
			company: 'Quantum',
			name: 'Wynton Marsalis',
			password: 'mist65',
			phone: '764-492-4300',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 7,
			admin: false,
			avatar: 'avatar7.jpg',
			email: 'wernerh2@gmail.com',
			company: 'Quantum',
			name: 'Werner Herzog',
			password: 'nist25',
			phone: '986-743-7454',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 8,
			admin: false,
			avatar: 'avatar1.jpg',
			email: 'owsley.stanley@gmail.com',
			company: 'Quantum',
			name: 'Owsley Stanley',
			password: 'bist15',
			phone: '628-773-3425',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 9,
			admin: false,
			avatar: 'avatar2.jpg',
			email: 'john.bell@gmail.com',
			company: 'Quantum',
			name: 'John Bell',
			password: 'sist05',
			phone: '628-773-3425',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 10,
			admin: false,
			avatar: 'avatar3.jpg',
			email: 'ccorea@gmail.com',
			company: 'Quantum',
			name: 'Chick Corea',
			password: 'gist35',
			phone: '364-220-3101',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 11,
			admin: false,
			avatar: 'avatar4.jpg',
			email: 'tomhardy53@gmail.com',
			company: 'Quantum',
			name: 'Tom Hardy',
			password: 'gist35',
			phone: '925-879-1199',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 12,
			admin: false,
			avatar: 'avatar5.jpg',
			email: 'trice@gmail.com',
			company: 'Quantum',
			name: 'Tony Rice',
			password: 'gist35',
			phone: '712-297-8809',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 13,
			admin: true,
			avatar: 'avatar2.jpg',
			email: 'alton.medina@gmail.com',
			company: 'Quantum',
			name: 'Alton Medina',
			password: 'nolimes',
			phone: '529-484-4219',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 14,
			admin: true,
			avatar: 'avatar5.jpg',
			email: 'yannisgardner@gmail.com',
			company: 'Quantum',
			name: 'Yannis Gardner',
			password: 'gist35',
			phone: '440-448-3672',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 15,
			admin: true,
			avatar: 'avatar5.jpg',
			email: 'r.macgregor@gmail.com',
			company: 'Quantum',
			name: 'Romana Macgregor',
			password: 'gist35',
			phone: '644-204-1929',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
		{
			_id: 16,
			admin: true,
			avatar: 'avatar5.jpg',
			email: 'simrapena8@gmail.com',
			company: 'Quantum',
			name: 'Simra Pena',
			password: 'gist35',
			phone: '636-715-5166',
			location: 'United States',
			timeZone: '(UTC-05:00) Eastern Time (US & Canada)',
		},
	],

	tickets: [
		{
			subject: 'Test Ticket ####',
			status: 0,
			priority: 4,
			created: '2022-02-08T19:33:05.171Z',
			modified: '2022-02-08T19:33:05.172Z',
			id: '6202c57117b3f3c14a2818fa',
			requesters: [
				{
					name: 'KYLE FISHER',
					id: '61fa8d3c9258924010da3821',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
		},
		{
			subject: 'asd',
			status: 0,
			priority: 1,
			created: '2022-02-08T08:10:24.066Z',
			modified: '2022-02-08T08:10:24.066Z',
			id: '620225707fb893199836bb5f',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
		},
		{
			subject: 'Test',
			status: 0,
			priority: 2,
			created: '2022-02-08T08:10:15.423Z',
			modified: '2022-02-08T08:10:15.423Z',
			id: '620225677fb893199836bb5e',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
		},
		{
			subject: 'Test',
			status: 0,
			priority: 3,
			created: '2022-02-08T08:10:05.871Z',
			modified: '2022-02-08T08:10:05.871Z',
			id: '6202255d7fb893199836bb5d',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
		},
		{
			subject: 'Test',
			status: 0,
			priority: 3,
			created: '2022-02-08T08:09:52.299Z',
			modified: '2022-02-08T08:09:52.299Z',
			id: '620225507fb893199836bb5c',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
		},
		{
			subject: ' ',
			status: 0,
			priority: 2,
			created: '2022-02-07T09:03:46.813Z',
			modified: '2022-02-07T09:03:46.814Z',
			id: '6200e072ea3e67473480d247',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
		},
		{
			subject: 'Subject',
			status: 0,
			priority: 3,
			created: '2022-02-07T09:00:21.231Z',
			modified: '2022-02-07T09:00:21.232Z',
			id: '6200dfa522c40521f8174f50',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'CAMERON T',
					id: '61fa6a6baba75f321ed5290a',
				},
			],
		},
		{
			subject: 'test',
			status: 0,
			priority: 2,
			created: '2022-02-06T21:39:58.644Z',
			modified: '2022-02-06T21:39:58.646Z',
			id: '6200402e99e16a61ec324e9c',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61f5417738a101a509693b19',
				},
			],
		},
		{
			subject: 'dummy',
			status: 0,
			priority: 1,
			created: '2022-02-02T18:44:53.468Z',
			modified: '2022-02-02T18:44:53.468Z',
			id: '61fad125a751693fb5cffaab',
			requesters: [
				{
					name: 'ANAND VERMA',
					id: '61ea6f28e70b271490c7f5a8',
				},
			],
			receivers: [
				{
					name: 'ANAND VERMA',
					id: '61f5417738a101a509693b19',
				},
			],
		},
		{
			subject: 'TEST TICKET from meeting',
			status: 0,
			priority: 4,
			created: '2022-02-02T16:19:05.758Z',
			modified: '2022-02-02T16:19:05.758Z',
			id: '61faaef92b24f63c3443f669',
			requesters: [
				{
					name: 'KYLE FISHER',
					id: '61fa8d3c9258924010da3821',
				},
			],
			receivers: [
				{
					name: 'CAMERON T',
					id: '61fa6a6baba75f321ed5290a',
				},
			],
		},
	],

	users: {
		1: {
			admin: false,
			avatar: 'avatar1.jpg',
			email: 'gbusy@gmail.com',
			name: 'Gary Busy',
			password: 'sky74',
		},
		2: {
			admin: true,
			avatar: 'avatar2.jpg',
			email: 'dlynch37@gmail.com',
			name: 'David Lynch',
			password: 'nolimes',
		},
		3: {
			admin: false,
			avatar: 'avatar3.jpg',
			email: 'mike.shannon@gmail.com',
			name: 'Michael Shannon',
			password: 'hist95',
		},
		4: {
			admin: false,
			avatar: 'avatar4.jpg',
			email: 'mhouser@gmail.com',
			name: 'Michael Houser',
			password: 'list92',
		},
		5: {
			admin: false,
			avatar: 'avatar5.jpg',
			email: 'phillesh10@gmail.com',
			name: 'Phil Lesh',
			password: 'kist75',
		},
		6: {
			admin: false,
			avatar: 'avatar6.jpg',
			email: 'wmarsalis@gmail.com',
			name: 'Wynton Marsalis',
			password: 'mist65',
		},
		7: {
			admin: false,
			avatar: 'avatar7.jpg',
			email: 'wernerh2@gmail.com',
			name: 'Werner Herzog',
			password: 'nist25',
		},
		8: {
			admin: false,
			avatar: 'avatar1.jpg',
			email: 'owsley.stanley@gmail.com',
			name: 'Owsley Stanley',
			password: 'bist15',
		},
		9: {
			admin: false,
			avatar: 'avatar2.jpg',
			email: 'john.bell@gmail.com',
			name: 'John Bell',
			password: 'sist05',
		},
		10: {
			admin: false,
			avatar: 'avatar3.jpg',
			email: 'ccorea@gmail.com',
			name: 'Chick Corea',
			password: 'gist35',
		},
		11: {
			admin: false,
			avatar: 'avatar4.jpg',
			email: 'tomhardy53@gmail.com',
			name: 'Tom Hardy',
			password: 'gist35',
		},
		12: {
			admin: false,
			avatar: 'avatar5.jpg',
			email: 'trice@gmail.com',
			name: 'Tony Rice',
			password: 'gist35',
		},
		13: {
			admin: true,
			avatar: 'avatar2.jpg',
			email: 'alton.medina@gmail.com',
			name: 'Alton Medina',
			password: 'nolimes',
		},
		14: {
			admin: true,
			avatar: 'avatar5.jpg',
			email: 'yannisgardner@gmail.com',
			name: 'Yannis Gardner',
			password: 'gist35',
		},
		15: {
			admin: true,
			avatar: 'avatar5.jpg',
			email: 'r.macgregor@gmail.com',
			name: 'Romana Macgregor',
			password: 'gist35',
		},
		16: {
			admin: true,
			avatar: 'avatar5.jpg',
			email: 'simrapena8@gmail.com',
			name: 'Simra Pena',
			password: 'gist35',
		},
	},
	adminUsers: [
		{
			id: 1,
			email: 'cookies@sesemestreet.com',
			buisness: 'Cookie Monster Inc',
			agent: 'Sam Barnes',
		},
		{
			id: 2,
			email: 'SteveJobs@apple.com',
			buisness: 'Apple',
			agent: 'Steve Jobs',
		},
		{
			id: 3,
			email: 'cobrakai@hotmail.com',
			buisness: 'Cobra Kai',
			agent: 'Johnny Lawrence',
		},
	],
};

export default dataSet;
