import { createSlice } from '@reduxjs/toolkit';
export const initialState = {

  enterpriseData: [],
  isLoadingEnterprise: false,
  enterpriseCount: 0,
  enterprisePageNo: 0,

  

}

export const enterprisesSlice = createSlice({
  name: 'enterprises',
  initialState,
  reducers: {
    updateEnterpriseLoadingStatus: (state, action) => {
      state.isLoadingEnterprise = action.payload;
    },
    updateEnterpriseData: (state, action) => {
      state.enterpriseData = action.payload;
    },
    updateEnterpriseCount: (state, action) => {
      state.enterpriseCount = action.payload;
    },
    updateEnterprisePageNo: (state, action) => {
      state.enterprisePageNo = action.payload;
    }
  }
});

export const {
  updateEnterpriseLoadingStatus,
   updateEnterpriseData,
   updateEnterpriseCount, 
   updateEnterprisePageNo
  } = enterprisesSlice.actions;

export default enterprisesSlice.reducer;
