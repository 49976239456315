import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	//updated Reducers
	data: [],
	pageNo: 0,
};

export const announcementSlice = createSlice({
	name: 'agents',
	initialState,
	reducers: {
		updateAnnouncementData: (state, action) => {
			state.data = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateAnnouncementData } = announcementSlice.actions;

export default announcementSlice.reducer;
