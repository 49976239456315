import { createSlice } from '@reduxjs/toolkit'

export const initialState = {

    slaData: [],
    isLoadingSla: false,
    slaCount: 0,
    slaPageNo: 0,

    businessHoursData: [],
    isLoadingbusinessHours: false,
    businessHoursCount: 0,
    businessHoursPageNo: 0,

}

export const businessRulesSlice = createSlice({
    name: 'businessRules',
    initialState,
    reducers: {
        // SLA
        updateSLALoadingStatus: (state, action) => {
            state.isLoadingSla = action.payload;
        },
        updateSlaData: (state, action) => {
            state.slaData = action.payload;
        },
        updateSlaCount: (state, action) => {
            state.slaCount = action.payload;
        },
        updateSlaPageNo: (state, action) => {
            state.slaPageNo = action.payload;
        },
        // Business hours
        updateBusinessHoursLoadingStatus: (state, action) => {
            state.isLoadingbusinessHours = action.payload;
        },
        updateBusinessHoursData: (state, action) => {
            state.businessHoursData = action.payload;
        },
        updateBusinessHoursCount: (state, action) => {
            state.businessHoursCount = action.payload;
        },
        updateBusinessHoursPageNo: (state, action) => {
            state.businessHoursPageNo = action.payload;
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    updateSLALoadingStatus,
    updateSlaData,
    updateSlaCount,
    updateSlaPageNo,
    updateBusinessHoursLoadingStatus,
    updateBusinessHoursData,
    updateBusinessHoursCount,
    updateBusinessHoursPageNo } = businessRulesSlice.actions

export default businessRulesSlice.reducer