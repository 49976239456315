import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@mui/material/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { closePopupAction } from '../redux/popupSlice/popupActions';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import EnterpriseSelectionModal from 'src/quantum_ticketing/Admin/modules/Emails/EnterpriseSelectionModal';

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function PopupContainer() {
    const dispatch = useDispatch()
    const popupState = useSelector(state => state.popup.popupState);
    const popupType = useSelector(state => state.popup.popupType);
    const showPopupCloseButton = useSelector(state => state.popup.showPopupCloseButton);
    const closePopupOnClickOutside = useSelector(state => state.popup.closePopupOnClickOutside);
    const popupMaxWidth = useSelector(state => state.popup.popupMaxWidth);
    const isFullScreenPopup = useSelector(state => state.popup.isFullScreenPopup);
    const popupScrollType = useSelector(state => state.popup.popupScrollType);
    const popupDataObj = useSelector(state => state.popup.popupDataObj);

    const handlePopupClose = () => {
        if (closePopupOnClickOutside) {
            dispatch(closePopupAction());
        }
    }


    return (
        <React.Fragment>
            <Dialog onClose={handlePopupClose} open={popupState} maxWidth={popupMaxWidth} fullScreen={isFullScreenPopup} scroll={popupScrollType} TransitionComponent={Transition}>

                {
                    showPopupCloseButton && <Box onClick={handlePopupClose} sx={{
                        position: "absolute", right: "5px", top: "5px", zIndex: "1", width: "40px", height: "40px",
                        display: "grid", placeItems: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        backgroundColor: "rgb(242, 242, 242)",
                        transition: "transform 0.2s cubic-bezier(.6, 0.05, .01, 1)",
                        '&:hover': {
                            transform: "scale(1.2)"
                        },
                    }}><FontAwesomeIcon icon={faClose} size="lg" /></Box>
                }


                {
                    {
                        "enterpriseSelectionModal": <EnterpriseSelectionModal />,
                    }[popupType]
                }

            </Dialog>
        </React.Fragment>
    )
}
