import moment from 'moment';
import { profileService } from 'src/services/profile.service';

export const getUserIdKey = (key) => {
	return `${key}__${profileService?.profile?.userId ?? ''}`;
};

export const isValidUrl = (url) => {
	if (!url) {
		return;
	}
	const urlPattern =
		// eslint-disable-next-line no-useless-escape
		/^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}([-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/;

	return urlPattern.test(url);
};

export const prepareProfileImage = (profile) => {
	if (!profile || !profile.gUId || profile.gToken) {
		return '';
	}

	return;
};

export const formatMilliseconds = (milliseconds) => {
	const duration = moment.duration(milliseconds);
	const days = duration.days();
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	let formattedDuration = '';
	if (days > 0) {
		formattedDuration += `${days} days `;
	}
	if (hours > 0) {
		formattedDuration += `${hours} hrs `;
	}
	if (minutes > 0 && days <= 0) {
		formattedDuration += `${minutes} mins `;
	}
	if (seconds > 0 && days <= 0 && hours <= 0) {
		formattedDuration += `${seconds} secs`;
	}

	return formattedDuration.trim();
};
