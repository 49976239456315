import { profileService } from './profile.service';
import config from '../config';

export const utilityService = {
    toTitleCase,
    stringAvatar,
    profileAvatar,
    isEndUserBrowsing,
    showLoader,
    removeLoader
};
function toTitleCase(str) {
    return str?.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

function profileAvatar(userId) {
    return `${config.apiURL}${config.profileImage}/${userId}?token=${profileService.token}`
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
            marginRight: '8px',
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export const  stringToColor=(string) =>{
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
}

function isEndUserBrowsing() {
    return  window.location.host != config.baseUrl.substr(config.baseUrl.indexOf('://') + 3);
}

function toogleLoader(show = true) {
    if (show)
        document.body.classList.add("temp-loading");
    else
        document.body.classList.remove("temp-loading");
}

function showLoader() {
    toogleLoader(true);
}
function removeLoader() {
    toogleLoader(false);
}