import produce from 'immer';
import moment from 'moment';
import dataSet from '../../../mockDB/dataSet';
import * as _ from 'lodash';

export const TicketPageReducer = (state, action) => {
	switch (action.type) {
		case 'assignTicket': {
			return produce(state, (draft) => {
				const userIds = Object.entries(draft.users).map((e) => e[0]);
				const userNames = Object.entries(draft.users).map((e) => e[1].name);
				const matches = [
					...new Set([
						...action.payload.assignees
							.filter((e) => userNames.includes(e.name))
							.map((e) =>
								parseInt(userIds[userNames.findIndex((f) => f === e.name)]),
							),
						...draft.selectedTickets
							.map((e) => draft.tickets.find((f) => f._id === e).requesterId)
							.flat(1),
					]),
				];
				draft.tickets = draft.tickets.map((e) => {
					if (draft.selectedTickets.includes(e._id)) {
						return { ...e, receiverId: matches };
					}
					return e;
				});
				draft.selectedTickets = [];
			});
		}
		case 'setBaseMergeTicket': {
			return produce(state, (draft) => {
				draft.baseMergeTicket = action.payload.newBaseMergeTicket;
			});
		}
		case 'setMergingIntoTickets': {
			return produce(state, (draft) => {
				draft.mergingIntoTickets = action.payload.mergingIntoTickets;
			});
		}
		case 'executeMerge': {
			return produce(state, (draft) => {
				const convertedTickets = draft.selectedTickets.map((e) =>
					draft.tickets.find((f) => f._id === e),
				);
				const requesters = [
					...new Set(convertedTickets.map((e) => e.requesterId).flat(1)),
				];
				const receivers = [
					...new Set(convertedTickets.map((e) => e.receiverId).flat(1)),
				];
				const messages = [...convertedTickets.map((e) => e.history).flat(1)];
				draft.baseMergeTicket.history = [
					...draft.baseMergeTicket.history,
					...messages,
				];
				draft.baseMergeTicket.requesterId = [
					...new Set(draft.baseMergeTicket.requesterId.concat(requesters)),
				];
				draft.baseMergeTicket.receiverId = [
					...new Set(draft.baseMergeTicket.receiverId.concat(receivers)),
				];
				draft.tickets = draft.tickets
					.filter(
						(e) =>
							!draft.selectedTickets
								.filter((f) => f !== draft.baseMergeTicket._id)
								.includes(e._id),
					)
					.map((e) => {
						if (e._id === draft.baseMergeTicket._id) {
							return draft.baseMergeTicket;
						}
						return e;
					});
				draft.selectedTickets = [];
				draft.mergingMessage = '';
				draft.baseMergeTicket = {};
				draft.mergingIntoTickets = [];
				draft.selectedUsers = [];
			});
		}
		case 'updateMergeMessage': {
			return produce(state, (draft) => {
				draft.mergingMessage = action.payload.newMergingMessage;
			});
		}
		case 'bulkReply': {
			return produce(state, (draft) => {
				draft.tickets = draft.tickets.map((e) => {
					if (draft.selectedTickets.includes(e._id)) {
						e.history = [
							...e.history,
							{
								date: new moment().format('MM-DD-YYYY'),
								message: action.payload.replyMessage,
								sender: 'Curr User',
							},
						];
						e.status = action.payload.replyStatus;
						return e;
					}
					return e;
				});
				draft.selectedTickets = [];
			});
		}
		case 'addTicket': {
			return produce(state, (draft) => {
				const stats = action.payload.newticket;
				const num = Math.max(...draft.tickets.map((e) => e._id));
				draft.tickets = [
					...draft.tickets,
					{
						...stats,
						_id: Math.max(...draft.tickets.map((e) => e._id)) + 1,
						creationDate: new moment(),
						lastUpdated: new moment(),
						status: 'New',
						history: [],
						updateHistory: [],
					},
				];
			});
		}
		case 'setHistoricalTickets': {
			return produce(state, (draft) => {
				draft.historicalTickets = action.payload.historicalTickets;
			});
		}
		case 'setTickets': {
			return produce(state, (draft) => {
				draft.tickets = action.payload.tickets;
			});
		}
		case 'setSelectedTickets': {
			return produce(state, (draft) => {
				draft.selectedTickets = action.payload.selectedTickets;
			});
		}
		case 'setAssignedTickets': {
			return produce(state, (draft) => {
				draft.assignedTicketIds = action.payload.ticketIds;
			});
		}
		case 'addSelectedTicket': {
			return produce(state, (draft) => {
				draft.selectedTickets = [
					...draft.selectedTickets,
					action.payload.selection,
				];
			});
		}
		case 'removeSelectedTicket': {
			return produce(state, (draft) => {
				draft.selectedTickets = draft.selectedTickets.filter(
					(e) => e !== action.payload.removedTicket,
				);
			});
		}
		case 'removeTicketFromTickets': {
			return produce(state, (draft) => {
				draft.tickets = draft.tickets.filter(
					(e) => e._id !== action.payload.removedTicket._id,
				);
			});
		}
		case 'setSelectedUsers': {
			return produce(state, (draft) => {
				draft.selectedUsers = action.payload.users;
			});
		}
		case 'addTicketToHistoricalTickets': {
			return produce(state, (draft) => {
				draft.historicalTickets = [
					...draft.historicalTickets,
					{ ...action.payload.newTicket, status: 'closed' },
				];
			});
		}
		case 'addTicketsToHistoricalTickets': {
			return produce(state, (draft) => {
				draft.historicalTickets = [
					...draft.historicalTickets,
					...action.payload.tickets,
				];
			});
		}
		case 'updateCurrentTicketMessageHistory': {
			return produce(state, (draft) => {
				draft.currentTicket.history = [
					...draft.currentTicket.history,
					{
						date: moment().format('MM-DD-YYYY'),
						message: action.payload.message,
						sender: 'Default Sender',
					},
				];
			});
		}
		case 'moveTicket': {
			return produce(state, (draft) => {
				// direction : true - left , false - right
				const currIndex = draft.tickets.findIndex(
					(eachTicket) => eachTicket._id === draft.currentTicket._id,
				);
				if (action.payload.direction) {
					if (currIndex === 0) {
						draft.currentTicket = draft.tickets[draft.tickets.length - 1];
					} else {
						draft.currentTicket = draft.tickets[currIndex - 1];
					}
				} else {
					// moving right
					if (currIndex === draft.tickets.length - 1) {
						draft.currentTicket = draft.tickets[0];
					} else {
						draft.currentTicket = draft.tickets[currIndex + 1];
					}
				}
			});
		}
		case 'updateRequesters': {
			return produce(state, (draft) => {
				draft.currentTicket.requesterId = action.payload.requesters;
				draft.currentTicket.updateHistory = [
					...draft.currentTicket.updateHistory,
					{
						user: 'Default user',
						date: `${moment().format('YYYY MM DD hh:mm')}`,
						description: 'Updated Requesters',
					},
				];
			});
		}
		case 'updateReceivers': {
			return produce(state, (draft) => {
				draft.currentTicket.receiverId = action.payload.receivers;
				draft.currentTicket.updateHistory = [
					...draft.currentTicket.updateHistory,
					{
						user: 'Default user',
						date: `${moment().format('YYYY MM DD hh:mm')}`,
						description: 'Updated Receivers',
					},
				];
			});
		}
		case 'updateStatus': {
			return produce(state, (draft) => {
				draft.currentTicket.updateHistory = [
					...draft.currentTicket.updateHistory,
					{
						user: 'Default user',
						date: `${moment().format('YYYY MM DD hh:mm')}`,
						description: `Updated status from ${draft.currentTicket.status} to ${action.payload.newStatus}`,
					},
				];
				draft.currentTicket.status = action.payload.newStatus;
			});
		}
		case 'updateTicketWithId': {
			return produce(state, (draft) => {
				draft.currentTicket =
					draft.tickets[
						draft.tickets.findIndex(
							(eachTicket) => eachTicket._id === action.payload.findId,
						)
					];
			});
		}
		case 'updateTicket': {
			return produce(state, (draft) => {
				draft.currentTicket = action.payload.currentTicket;
			});
		}
		case 'toggleMergeModal': {
			return produce(state, (draft) => {
				draft.mergeModalDisplay = action.payload.mergeModalDisplay;
			});
		}
		case 'updateTicketUpdateButton': {
			return produce(state, (draft) => {
				draft.tickets[
					draft.tickets.findIndex(
						(eachTicket) => eachTicket._id === draft.currentTicket._id,
					)
				] = draft.currentTicket;
			});
		}
		case 'updateHistoryChangesAmount': {
			return produce(state, (draft) => {
				draft.historyChanges = action.payload.numHistoryChanges;
			});
		}
		case 'updateHistoryPages': {
			return produce(state, (draft) => {
				const pages = [];
				let subPages = [];
				for (
					let i = 0;
					i < draft.currentTicket.updateHistory.length;
					i += draft.historyChanges
				) {
					subPages = _.slice(
						draft.currentTicket.updateHistory,
						i,
						i + draft.historyChanges,
					);
					pages.push(subPages);
				}
				draft.historyPages = pages;
			});
		}
		case 'updateCurrentHistoryChangePage': {
			return produce(state, (draft) => {
				draft.currentHistoryChangePage = action.payload.currentPage;
			});
		}
		case 'appendFiles': {
			return produce(state, (draft) => {
				const fileNames = Object.keys(action.payload.appendedFiles)
					.map((eachName) => action.payload.appendedFiles[eachName].name)
					.filter((e) => !draft.fileNames.includes(e));
				const result = Object.entries(action.payload.appendedFiles).find(
					(e) => e[1].name === fileNames[0],
				);
				if (fileNames.length > 0) {
					draft.files = [...draft.files, action.payload.appendedFiles];
					draft.fileNames = [
						...draft.fileNames,
						fileNames.map(
							(eachName) =>
								Object.entries(action.payload.appendedFiles).find(
									(e) => e[1].name === eachName,
								)[1].name,
						),
					];
					const fileObjects = fileNames.map((eachFileName) => ({
						name: Object.entries(action.payload.appendedFiles).find(
							(e) => e[1].name === eachFileName,
						)[1].name,
						size: `${(
							Object.entries(action.payload.appendedFiles).find(
								(e) => e[1].name === eachFileName,
							)[1].size / 1000
						).toFixed(1)} kb`,
					}));
					let tmpFiles = [...draft.fileInfo];
					fileObjects.forEach((e) => tmpFiles.push(e));
					draft.fileInfo = tmpFiles;
				}
			});
		}
		case 'updateFiles': {
			return produce(state, (draft) => {
				const filesKeys = Object.keys(action.payload.uploadedFiles);
				draft.files = action.payload.uploadedFiles;
				draft.fileNames = filesKeys.map(
					(eachKey) => action.payload.uploadedFiles[eachKey].name,
				);
				draft.fileInfo = filesKeys.map((eachFileKey) => ({
					name: action.payload.uploadedFiles[eachFileKey].name,
					size: `${(
						action.payload.uploadedFiles[eachFileKey].size / 1000
					).toFixed(1)} kb`,
				}));
			});
		}
		case 'clearFiles': {
			return produce(state, (draft) => {
				draft.files = [];
				draft.fileNames = [];
				draft.fileInfo = [];
			});
		}
		case 'updateBottomNavbarDropdown': {
			return produce(state, (draft) => {
				draft.selectedTicketOption = action.payload.option;
			});
		}
		case 'cancelUpdates': {
			return produce(state, (draft) => {
				draft.currentTicket = draft.tickets.find(
					(eachTicket) => eachTicket.id === draft.currentTicket.id,
				);
			});
		}
		case 'updateAndResolve': {
			return produce(state, (draft) => {
				draft.tickets = draft.tickets.filter(
					(eachTicket) => eachTicket.id !== draft.currentTicket.id,
				);
				draft.historicalTickets = [
					...draft.historicalTickets,
					draft.currentTicket,
				];
				draft.currentTicket.status = 'Closed';
				dataSet.historicaltickets = draft.historicalTickets;
			});
		}
		case 'toggleSpinner': {
			return produce(state, (draft) => {
				draft.loading = action.payload.loading;
			});
		}
		case 'deleteFile': {
			return produce(state, (draft) => {
				let temp = Array.from(draft.files);
				temp.splice(action.payload.index, 1);
				draft.files = temp;
				let tmpInfo = [...draft.fileInfo];
				tmpInfo.splice(action.payload.index, 1);
				draft.fileInfo = [...tmpInfo];
			});
		}
		case 'toggleIsOpenTickets': {
			return produce(state, (draft) => {
				draft.isOpenTickets = !draft.isOpenTickets;
			});
		}
		case 'changeCurrentAdminPage': {
			return produce(state, (draft) => {
				draft.currentPage = action.payload.page;
			});
		}
		case 'changeCurrentAdminPageRightOptions': {
			return produce(state, (draft) => {
				draft.currentRightPage = action.payload.page;
			});
		}
		case 'toggleSort': {
			return produce(state, (draft) => {
				// payload:  { key }
				const value = draft.sortings[action.payload.sortType];
				if (value === 0) {
					// ascend
					draft.sortings[action.payload.sortType] = 1;
				} else if (value === 1) {
					// descend
					draft.sortings[action.payload.sortType] = -1;
				} else {
					// ascend
					draft.sortings[action.payload.sortType] = 1;
				}
			});
		}
		case 'sortTickets': {
			return produce(state, (draft) => {
				if (
					Object.entries(state.sortings)
						.map((e) => e[1])
						.reduce((a, b) => a + b) === 0
				) {
					// dont sort
				} else {
					// sort tickets
					const tmpTickets = [...draft.tickets];
					Object.entries(state.sortings).forEach((e) => {
						switch (e[0]) {
							case 'id': {
								if (e[1] > 0) {
									tmpTickets.sort((a, b) => {
										if (a.id > b.id) {
											return 1;
										} else if (a.id < b.id) {
											return -1;
										} else {
											return 0;
										}
									});
									draft.tickets = [...tmpTickets];
								} else {
									tmpTickets.sort((a, b) => {
										if (a > b) {
											return -1;
										} else if (a < b) {
											return 1;
										} else {
											return 0;
										}
									});
								}
								break;
							}
							case 'created': {
								if (e[1] > 0) {
									tmpTickets.sort((a, b) => {
										const momentA = moment(a.created, [
											'YYYY-MM-DD ',
											'TIME_MS',
										]);
										const momentB = moment(b.created, [
											'YYYY-MM-DD ',
											'TIME_MS',
										]);
										if (momentA.isAfter(momentB)) {
											return 1;
										} else if (momentA.isBefore(momentB)) {
											return -1;
										} else {
											return 0;
										}
									});
								}
								break;
							}
							case 'modified': {
								if (e[1] > 0) {
									tmpTickets.sort((a, b) => {
										const momentA = moment(a.created, [
											'YYYY-MM-DD ',
											'TIME_MS',
										]);
										const momentB = moment(b.created, [
											'YYYY-MM-DD ',
											'TIME_MS',
										]);
										if (momentA.isAfter(momentB)) {
											return 1;
										} else if (momentA.isBefore(momentB)) {
											return -1;
										} else {
											return 0;
										}
									});
								}
								break;
							}
							case 'priority': {
								if (e[1] > 0) {
									tmpTickets.sort((a, b) => a.priority - b.priority);
								}
								break;
							}
							case 'receivers': {
								if (e[1] > 0) {
									tmpTickets.sort((a, b) => {
										const receiversA = a.receivers.map(
											(e) => draft.users[e].name,
										);
										const receiversB = b.receivers.map(
											(e) => draft.users[e].name,
										);
										receiversA.sort();
										receiversB.sort();
										if (receiversA[0] > receiversB[0]) {
											return 1;
										} else if (receiversA[0] < receiversB[0]) {
											return -1;
										} else {
											return 0;
										}
									});
									draft.tickets = tmpTickets;
								}
								break;
							}
							case 'requesters': {
								if (e[1] > 0) {
									tmpTickets.sort((a, b) => {
										const requestersA = a.requesters.map(
											(e) => draft.users[e].name,
										);
										const requestersB = b.requesters.map(
											(e) => draft.users[e].name,
										);
										requestersA.sort();
										requestersB.sort();
										if (requestersA[0] > requestersB[0]) {
											return 1;
										} else if (requestersA[0] < requestersB[0]) {
											return -1;
										} else {
											return 0;
										}
									});
									draft.tickets = tmpTickets;
								}
								break;
							}
							case 'subject': {
								if (e[1] > 0) {
									tmpTickets.sort((a, b) => {
										if (a.subject > b.subject) {
											return 1;
										} else if (a.subject < b.subject) {
											return -1;
										} else {
											return 0;
										}
									});
									draft.tickets = tmpTickets;
								}
								break;
							}
							default: {
								// no sorting
								break;
							}
						}
					});
				}
			});
		}
		case 'openAdminModal': {
			return produce(state, (draft) => {
				draft.adminModals[action.payload.modal] = true;
			});
		}
		case 'closeAdminModal': {
			return produce(state, (draft) => {
				draft.adminModals[action.payload.modal] = false;
			});
		}
		case 'updateSelectedEnterprise': {
			return produce(state, (draft) => {
				draft.selectedEnterpriseForProductTab = action.payload;
			});
		}
		case 'storeEnterpriseList': {
			return produce(state, (draft) => {
				draft.enterpriseList = action.payload;
			});
		}
		default: {
			return { ...state };
		}
	}
};
