import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { RolesList } from '../components/util/RolesList';

import { profileService } from '../services/profile.service';

export const GuardedRoute = ({ children, excludeRole }) => {
    const token = profileService.token;
    const location = useLocation();

    if (!token) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }
    else if (excludeRole) {
        switch (excludeRole) {
            case RolesList.EndUser:
                if (profileService.isEndUser) {
                    return <Navigate to="/access-denied" replace />
                }
                break;
            default:
                break;
        }
    }
    return children;
};

export default GuardedRoute;
