import { MenuItem, Select, Typography } from '@mui/material'
import { fetchWrapper } from 'src/utils/fetch-wrapper';
import config from 'src/config'
import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateSelectedEnterpriseandFetchEmailTemplate } from 'src/core/redux/emailSlice/emailActions';
import { closePopupAction } from 'src/core/redux/popupSlice/popupSlice';

const EnterpriseSelectionModal = () => {

    const [enterpriseList, setenterpriseList] = useState([])
    const [selectedEnterprise, setSelectedEnterprise] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {

        fetchEnterpriseData()
        return () => {

        }
    }, [])

    const fetchEnterpriseData = () => {
        fetchWrapper.post(
            config.apiURL + "/admin/list-enterprises",
            {
                page: 0,
                size: 100
            }

        ).then(res => {
            setenterpriseList(res.data.rows)
            setSelectedEnterprise(res.data.rows?.[0])
        }).catch(e => {
            //TODO: handle error
        });
    }

    const handleSubmitClick = () => {
        dispatch(updateSelectedEnterpriseandFetchEmailTemplate(selectedEnterprise))
        dispatch(closePopupAction())
    }


    return (
        <div style={{ padding: "24px", minWidth: "500px" }}>
            <Typography variant='h6' sx={{ fontWeight: "bold" }}>Select a Enterprise</Typography>
            <Typography variant='body1' sx={{ fontWeight: "bold" }}>Enterprise</Typography>
            <div style={{ marginTop: "10px" }}>
                <Select label=" " value={selectedEnterprise} variant='outlined' fullWidth onChange={(e) => setSelectedEnterprise(e.target.value)}>
                    {
                        enterpriseList.map((enterprise, idx) => <MenuItem value={enterprise} key={idx}>{enterprise.name}</MenuItem >)
                    }
                </Select>
            </div>
            <div style={{ marginTop: "12px", display: "flex", justifyContent: "flex-end" }}>
                <Button disabled={!selectedEnterprise} onClick={handleSubmitClick} style={{ minWidth: "100px" }}>Go</Button>
            </div>
        </div>
    )
}

export default EnterpriseSelectionModal