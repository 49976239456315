import { Subject } from 'rxjs'; // TODO: npm i rxjs <--- extraneous dependency
import { filter } from 'rxjs/operators';

const alertSubject = new Subject();
const defaultId = 'default-alert';

export const alertService = {
    alert,
    clear,
    error,
    info,
    onAlert,
    success,
    warn
};

export const alertType = {
    error: 'error',
    info: 'info',
    success: 'success',
    warning: 'warning'
}

const options = {
  autoClose: true
}

function onAlert(id = defaultId) {
  return alertSubject.asObservable().pipe(filter(x => x && x.id === id));
}

function success(message) {
  alert({ ...options, message, type: alertType.success });
}

function error(message) {
  alert({ ...options, message, type: alertType.error });
}

function info(message) {
  alert({ ...options, message, type: alertType.info });
}

function warn(message) {
  alert({ ...options, message, type: alertType.warning });
}

function alert(alert) {
  alert.id = alert.id || defaultId;
  alertSubject.next(alert);
}

function clear(id = defaultId) {
  alertSubject.next({ id });
}
