import { configureStore } from '@reduxjs/toolkit'
import popupReducer from "./popupSlice/popupSlice"
import emailsReducer from "./emailSlice/emailSlice"
import businessRulesReducer from "./businessRulesSlice/businessRulesSlice"
import enterprisesReducer from "./enterprisesSlice/enterprisesSlice"
import surveysReducer from "./surveySlice/surveySlice"

import globalReducer from './globalSlice/globalSlice'
import quickResponseReducer from './quickResponsesSlice/quickResponseSlice';
import tagsReducer from './tagsSlice/tagsSlice';
import languageReducer from './languagesSlice/languageSlice';
import announcementReducer from './announcementSlice/announcementSlice';

export const store = configureStore({
    reducer: {
        popup: popupReducer,
        emails: emailsReducer,
        businessRules: businessRulesReducer,
        enterprises: enterprisesReducer,
        quickResponses:quickResponseReducer,
        tags:tagsReducer,
        surveys: surveysReducer,
        languages:languageReducer,
        global: globalReducer,
        announcement: announcementReducer
    },
})