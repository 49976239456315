import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    emailTemplates: [],
    category: 0,
    isLoading: false,
    count: 0,
    pageNo: 0,
    type: "agents-email",//agents-email,requester-email,cc-notification,
    selectedEnterprise: null,
    variables: [],
    mailBoxData: []
}

export const emailSlice = createSlice({
    name: 'emails',
    initialState,
    reducers: {

        updateLoadingStatus: (state, action) => {
            state.isLoading = action.payload;
        },
        updateEmailTemplatesData: (state, action) => {
            state.emailTemplates = action.payload
        },
        updateCount: (state, action) => {
            state.count = action.payload;
        },
        updatePageNo: (state, action) => {
            state.pageNo = action.payload;
        },
        updateType: (state, action) => {
            state.type = action.payload;
        },
        updateSelectedEnterprise: (state, action) => {
            state.selectedEnterprise = action.payload;
        },
        updateTemplateVariables: (state, action) => {
            state.variables = action.payload;
        },
        updateMailBoxData: (state, action) => {
            state.mailBoxData = action.payload
        },
        updateTemplateCategory: (state, action) => {
            state.category = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateLoadingStatus, updateEmailTemplatesData, updateCount, updatePageNo, updateType, updateSelectedEnterprise, updateTemplateVariables, updateMailBoxData, updateTemplateCategory } = emailSlice.actions

export default emailSlice.reducer